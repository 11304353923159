<template>
  <div>
    <h4>Add Video</h4>
    <form @submit.prevent="submit" v-if="! loading">
      <div class="form-group">
        <label>Video Title</label>
        <input
          type="text"
          placeholder="Video Title"
          v-model="video.title"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Sort Order</label>
        <input
          type="text"
          placeholder="Sort Order"
          v-model="video.sort"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Video URL</label>
        <input
          type="text"
          placeholder="Video URL"
          v-model="video.video_url"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Video Thumbnail</label>
        <input
          type="file"
          placeholder="Video Thumbnail"
          ref="thumbnail"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>About this video</label>
        <editor
          api-key="no-api-key"
          v-model="video.about"
          :init="{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
        />
      </div>
      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: {},
      loading: false
    };
  },

  methods: {
    submit() {
      this.loading = true

      let data = new FormData
      data.append('title', this.video.title)
      data.append('sort', this.video.sort)
      data.append('video_url', this.video.video_url)
      data.append('thumbnail', this.$refs.thumbnail.files[0])
      data.append('about', this.video.about)

      this.$axios
        .post(`/api/v1/admin/academy/${this.$route.params.week_id}/videos`, data)
        .then(() => {
          this.loading = false
          this.$router.push({
            name: 'admin.academy.weeks.show'
          });
        });
    },
  },
};
</script>