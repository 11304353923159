var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("Add Video")]),(! _vm.loading)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Video Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.title),expression:"video.title"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Video Title"},domProps:{"value":(_vm.video.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.video, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sort Order")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.sort),expression:"video.sort"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Sort Order"},domProps:{"value":(_vm.video.sort)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.video, "sort", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Video URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.video.video_url),expression:"video.video_url"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Video URL"},domProps:{"value":(_vm.video.video_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.video, "video_url", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Video Thumbnail")]),_c('input',{ref:"thumbnail",staticClass:"form-control",attrs:{"type":"file","placeholder":"Video Thumbnail"}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("About this video")]),_c('editor',{attrs:{"api-key":"no-api-key","init":{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount' ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help',
        }},model:{value:(_vm.video.about),callback:function ($$v) {_vm.$set(_vm.video, "about", $$v)},expression:"video.about"}})],1),_vm._m(0)]):_vm._e(),(_vm.loading)?_c('loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])}]

export { render, staticRenderFns }